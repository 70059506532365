import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        textAlign: 'center',
    },
}));

export default useStyles;
