import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        padding: '20px',
    },
    paper: {
        marginBottom: '20px',
        padding: '20px',
        borderRadius: '10px',
    },
    button: {
        marginTop: '20px',
    },
});

export default useStyles;