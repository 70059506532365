import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import useStyles from './Sala_espera.styles';

const SalaEspera = () => {
    const classes = useStyles();
    const [especialidades, setEspecialidades] = useState([]);
    const [empresa, setEmpresa] = useState('');

    useEffect(() => {
        // Obtener la empresa y especialidades desde el backend
        const fetchData = async () => {
            try {
                const response = await fetch('/api/sala_espera');
                const data = await response.json();
                setEmpresa(data.empresa);
                setEspecialidades(data.especialidades);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        fetchData();
    }, []);

    const handleOpenSala = (especialidad) => {
        const url = `https://turneroweb.com.ar/turnero?empresa=${empresa}&especialidad=${especialidad}`;
        window.open(url, '_blank');
    };

    const handleOpenGeneral = () => {
        const url = `https://turneroweb.com.ar/turnero?empresa=${empresa}`;
        window.open(url, '_blank');
    };

    return (
        <Box className={classes.container}>
            <Typography variant="h4" align="center" gutterBottom>
                Salas de Espera
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {especialidades.map((especialidad, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleOpenSala(especialidad)}
                        >
                            {especialidad}
                        </Button>
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={handleOpenGeneral}
                    >
                        General
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SalaEspera;
