import React, { useEffect, useState } from "react";
import {
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    CircularProgress,
    Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Llamado = () => {
    const [turnos, setTurnos] = useState(null); // Inicializamos como null para diferenciar entre "cargando" y "vacío"
    const [loading, setLoading] = useState(true); // Indicador de carga
    const theme = useTheme();

    // Cargar los turnos al montar el componente
    useEffect(() => {
        fetch("/api/llamado/turnos")
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Error al obtener los turnos");
                }
                return res.json();
            })
            .then((data) => {
                setTurnos(data); // Asignar los turnos disponibles
                setLoading(false); // Finalizar la carga
            })
            .catch((err) => {
                console.error("Error al cargar turnos:", err);
                setTurnos([]); // Si hay error, dejamos la lista vacía
                setLoading(false);
            });
    }, []);

    // Función para manejar las acciones (LLAMAR o FINALIZAR)
    const actualizarEstado = (id, estado) => {
        fetch("/api/llamado/actualizar", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id, estado }),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Error al actualizar el turno");
                }
                return res.json();
            })
            .then((data) => {
                if (data.success) {
                    // Actualizar el estado de los turnos
                    setTurnos((prevTurnos) =>
                        prevTurnos.map((turno) =>
                            turno.id === id ? { ...turno, llamado: estado } : turno
                        )
                    );
                } else {
                    alert("Error al actualizar el turno.");
                }
            })
            .catch((err) => {
                console.error("Error al actualizar turno:", err);
                alert("Error al actualizar turno.");
            });
    };

    // Indicador de carga
    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                bgcolor={theme.palette.background.default}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div>
            <Typography variant="h4" align="center" gutterBottom>
                Llamado de Turnos
            </Typography>
            {turnos && turnos.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nombre y Apellido</TableCell>
                            <TableCell>Motivo</TableCell>
                            <TableCell>Horario</TableCell>
                            <TableCell>Profesional</TableCell>
                            <TableCell>Acción</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {turnos.map((turno) => (
                            <TableRow key={turno.id}>
                                <TableCell>{turno.id}</TableCell>
                                <TableCell>{`${turno.nombre} ${turno.apellido}`}</TableCell>
                                <TableCell>{turno.motivo}</TableCell>
                                <TableCell>{turno.hora}</TableCell>
                                <TableCell>{turno.profesional}</TableCell>
                                <TableCell>
                                    {turno.llamado === 3 ? (
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => actualizarEstado(turno.id, 1)}
                                        >
                                            LLAMAR
                                        </Button>
                                    ) : turno.llamado === 1 ? (
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => actualizarEstado(turno.id, 0)}
                                        >
                                            FINALIZAR
                                        </Button>
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            Finalizado
                                        </Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Typography align="center" variant="h6" color="textSecondary">
                    No hay turnos disponibles para hoy.
                </Typography>
            )}
        </div>
    );
};

export default Llamado;
