import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';

const Login = ({ onLogin }) => {
    const [credentials, setCredentials] = useState({ usuario: '', password: '' });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials),
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('empresa', data.empresa); // Guardar la empresa
                onLogin(); // Notifica al componente App que el usuario está autenticado
                navigate('/home');
            } else {
                setError(data.error || 'Error al iniciar sesión.');
            }
        } catch (err) {
            setError('Error al conectar con el servidor.');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                padding: 2,
            }}
        >
            {/* Logo */}
            <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/logo.png`} // Ruta correcta desde public
                alt="Logo"
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    marginBottom: 2,
                }}
            />
            
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Usuario"
                    variant="outlined"
                    name="usuario"
                    value={credentials.usuario}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Contraseña"
                    variant="outlined"
                    type="password"
                    name="password"
                    value={credentials.password}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                >
                    Ingresar
                </Button>
            </form>
            {error && (
                <Typography
                    color="error"
                    sx={{ marginTop: 2, textAlign: 'center' }}
                >
                    {error}
                </Typography>
            )}
        </Box>
    );
};

export default Login;
