import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Checkbox,
    FormControlLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    MenuItem,
} from '@mui/material';
import useStyles from './Pacientes.styles';

const Pacientes = () => {
    const classes = useStyles();

    const [formData, setFormData] = useState({
        nombres: '',
        apellido: '',
        documento: '',
        fecha_nacimiento: '',
        sexo: '',
        estado_civil: '',
        fecha_ingreso: new Date().toISOString().split('T')[0],
        domicilio_calle: '',
        domicilio_numero: '',
        localidad: '',
        provincia: '',
        pais: '',
        codigo_postal: '',
        telefono: '',
        mail: '',
        obra_social: '',
        numero_afiliado: '',
        plan_afiliado: '',
        discapacitado: false,
        recurso_amparo: false,
        coseguro: '',
    });

    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prev) => ({ ...prev, [name]: checked }));
    };

    const handleSave = async () => {
        const method = selectedPatient ? 'PUT' : 'POST';
        const endpoint = selectedPatient ? `/api/pacientes/${selectedPatient.id}` : '/api/pacientes';

        try {
            const response = await fetch(endpoint, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                alert('Paciente guardado exitosamente');
                fetchResults();
                setFormData({
                    nombres: '',
                    apellido: '',
                    documento: '',
                    fecha_nacimiento: '',
                    sexo: '',
                    estado_civil: '',
                    fecha_ingreso: new Date().toISOString().split('T')[0],
                    domicilio_calle: '',
                    domicilio_numero: '',
                    localidad: '',
                    provincia: '',
                    pais: '',
                    codigo_postal: '',
                    telefono: '',
                    mail: '',
                    obra_social: '',
                    numero_afiliado: '',
                    plan_afiliado: '',
                    discapacitado: false,
                    recurso_amparo: false,
                    coseguro: '',
                });
                setSelectedPatient(null);
            } else {
                alert('Error al guardar paciente');
            }
        } catch (error) {
            console.error('Error al guardar el paciente:', error);
        }
    };

    const fetchResults = async () => {
        try {
            const response = await fetch(`/api/pacientes?search=${search}`);
            const data = await response.json();
            setResults(data);
        } catch (error) {
            console.error('Error al buscar pacientes:', error);
        }
    };

    const handleSelect = (patient) => {
        const formattedPatient = {
            ...patient,
            fecha_nacimiento: patient.fecha_nacimiento
                ? new Date(patient.fecha_nacimiento).toISOString().split('T')[0]
                : '', // Formatear la fecha al formato YYYY-MM-DD
        };
        setSelectedPatient(patient);
        setFormData(formattedPatient);
    };

    useEffect(() => {
        if (selectedPatient) {
            const formattedPatient = {
                ...selectedPatient,
                fecha_nacimiento: selectedPatient.fecha_nacimiento
                    ? new Date(selectedPatient.fecha_nacimiento).toISOString().split('T')[0]
                    : '', // Formatear la fecha al formato YYYY-MM-DD
            };
            setFormData(formattedPatient);
        }
    }, [selectedPatient]);

    return (
        <Box className={classes.container}>
            <Typography variant="h5" gutterBottom>
                Gestión de Pacientes
            </Typography>

            {/* Formulario */}
            <Paper className={classes.form}>
                <Typography variant="h6">Formulario</Typography>
                <Grid container spacing={2}>
                    {[
                        { label: 'Nombres', name: 'nombres' },
                        { label: 'Apellido', name: 'apellido' },
                        { label: 'Documento', name: 'documento' },
                        { label: 'Fecha de Nacimiento', name: 'fecha_nacimiento', type: 'date' },
                        { label: 'Sexo', name: 'sexo', select: true, options: ['M', 'F'] },
                        { label: 'Estado Civil', name: 'estado_civil', select: true, options: ['Soltero', 'Casado', 'Divorciado', 'Viudo'] },
                        { label: 'Domicilio Calle', name: 'domicilio_calle' },
                        { label: 'Domicilio Número', name: 'domicilio_numero' },
                        { label: 'Localidad', name: 'localidad' },
                        { label: 'Provincia', name: 'provincia' },
                        { label: 'País', name: 'pais' },
                        { label: 'Código Postal', name: 'codigo_postal' },
                        { label: 'Teléfono', name: 'telefono' },
                        { label: 'Email', name: 'mail' },
                        { label: 'Obra Social', name: 'obra_social' },
                        { label: 'Número de Afiliado', name: 'numero_afiliado' },
                        { label: 'Plan Afiliado', name: 'plan_afiliado' },
                        { label: 'Coseguro', name: 'coseguro' },
                    ].map((field, idx) => (
                        <Grid item xs={12} md={6} key={idx}>
                            <TextField
                                label={field.label}
                                name={field.name}
                                value={formData[field.name]}
                                onChange={handleChange}
                                fullWidth
                                className={classes.field}
                                type={field.type || 'text'}
                                select={field.select || false}
                            >
                                {field.options &&
                                    field.options.map((option) => (
                                        <MenuItem value={option} key={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.discapacitado}
                                    onChange={handleCheckboxChange}
                                    name="discapacitado"
                                />
                            }
                            label="Discapacitado"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.recurso_amparo}
                                    onChange={handleCheckboxChange}
                                    name="recurso_amparo"
                                />
                            }
                            label="Recurso de Amparo"
                        />
                    </Grid>
                </Grid>
                <Box className={classes.buttonGroup}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Guardar
                    </Button>
                    <Button variant="outlined" onClick={() => setFormData({ ...formData, ...selectedPatient })}>
                        Limpiar
                    </Button>
                </Box>
            </Paper>

            {/* Buscar y tabla */}
            <Paper className={classes.searchSection}>
                <Typography variant="h6">Buscar Pacientes</Typography>
                <TextField
                    fullWidth
                    label="Buscar"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className={classes.field}
                />
                <Button variant="contained" color="secondary" onClick={fetchResults}>
                    Buscar
                </Button>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Apellido</TableCell>
                            <TableCell>Documento</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((patient) => (
                            <TableRow
                                key={patient.id}
                                hover
                                onClick={() => handleSelect(patient)}
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell>{patient.nombres}</TableCell>
                                <TableCell>{patient.apellido}</TableCell>
                                <TableCell>{patient.documento}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    );
};

export default Pacientes;
