import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Grid,
} from '@mui/material';

const Usuarios = () => {
    const [formData, setFormData] = useState({
        usuario: '',
        clave: '',
        mail: '',
        telefono: '',
        empresa: '',
        rol: 'usuario',
        especialidad: '',
        consultorio: '',
    });
    const [usuarios, setUsuarios] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const fetchUsuarios = async () => {
        try {
            const response = await fetch('/api/usuarios');
            const data = await response.json();
            setUsuarios(data);
        } catch (err) {
            console.error('Error al obtener usuarios:', err);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await fetch('/api/usuarios', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            setFormData({
                usuario: '',
                clave: '',
                mail: '',
                telefono: '',
                empresa: '',
                rol: 'usuario',
                especialidad: '',
                consultorio: '',
            });
            fetchUsuarios();
        } catch (err) {
            console.error('Error al crear usuario:', err);
        }
    };

    const handleEdit = (user) => {
        setEditingUser({
            ...user,
            especialidad: user.especialidad || '', // Asegurar que no sea undefined
            consultorio: user.consultorio || '', // Asegurar que no sea undefined
        });
        setIsModalOpen(true);
    };

    const handleUpdate = async () => {
        const updatedUser = { ...editingUser };
        if (!updatedUser.clave) delete updatedUser.clave; // Si la clave está vacía, no se incluye en la actualización.

        try {
            await fetch(`/api/usuarios/${editingUser.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedUser),
            });
            fetchUsuarios();
            setIsModalOpen(false);
            setEditingUser(null);
        } catch (err) {
            console.error('Error al actualizar usuario:', err);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('¿Desea eliminar el usuario?')) {
            try {
                await fetch(`/api/usuarios/${id}`, { method: 'DELETE' });
                fetchUsuarios();
            } catch (err) {
                console.error('Error al eliminar usuario:', err);
            }
        }
    };

    useEffect(() => {
        fetchUsuarios();
    }, []);

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom textAlign="center">
                Gestión de Usuarios
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Usuario"
                            name="usuario"
                            value={formData.usuario}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Clave"
                            name="clave"
                            type="password"
                            value={formData.clave}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Mail"
                            name="mail"
                            value={formData.mail}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Teléfono"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Empresa"
                            name="empresa"
                            value={formData.empresa}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            name="rol"
                            value={formData.rol}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            <MenuItem value="usuario">Usuario</MenuItem>
                            <MenuItem value="admin">Administrador</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Especialidad"
                            name="especialidad"
                            value={formData.especialidad}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Consultorio"
                            name="consultorio"
                            value={formData.consultorio}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                    Enviar
                </Button>
            </form>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Usuario</TableCell>
                        <TableCell>Mail</TableCell>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Empresa</TableCell>
                        <TableCell>Especialidad</TableCell>
                        <TableCell>Consultorio</TableCell>
                        <TableCell>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usuarios.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell>{user.usuario}</TableCell>
                            <TableCell>{user.mail}</TableCell>
                            <TableCell>{user.telefono}</TableCell>
                            <TableCell>{user.empresa}</TableCell>
                            <TableCell>{user.especialidad || '-'}</TableCell>
                            <TableCell>{user.consultorio || '-'}</TableCell>
                            <TableCell>
                                <Button onClick={() => handleEdit(user)}>Modificar</Button>
                                <Button color="error" onClick={() => handleDelete(user.id)}>
                                    Eliminar
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {editingUser && (
                <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <DialogTitle>Modificar Usuario</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Usuario"
                                    value={editingUser.usuario}
                                    onChange={(e) =>
                                        setEditingUser({ ...editingUser, usuario: e.target.value })
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Clave"
                                    type="password"
                                    onChange={(e) =>
                                        setEditingUser({ ...editingUser, clave: e.target.value })
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Mail"
                                    value={editingUser.mail}
                                    onChange={(e) =>
                                        setEditingUser({ ...editingUser, mail: e.target.value })
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Teléfono"
                                    value={editingUser.telefono}
                                    onChange={(e) =>
                                        setEditingUser({ ...editingUser, telefono: e.target.value })
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Empresa"
                                    value={editingUser.empresa}
                                    onChange={(e) =>
                                        setEditingUser({ ...editingUser, empresa: e.target.value })
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Especialidad"
                                    value={editingUser.especialidad}
                                    onChange={(e) =>
                                        setEditingUser({ ...editingUser, especialidad: e.target.value })
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Consultorio"
                                    value={editingUser.consultorio}
                                    onChange={(e) =>
                                        setEditingUser({ ...editingUser, consultorio: e.target.value })
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    value={editingUser.rol}
                                    onChange={(e) =>
                                        setEditingUser({ ...editingUser, rol: e.target.value })
                                    }
                                    fullWidth
                                >
                                    <MenuItem value="usuario">Usuario</MenuItem>
                                    <MenuItem value="admin">Administrador</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUpdate} variant="contained" color="primary">
                            Guardar
                        </Button>
                        <Button onClick={() => setIsModalOpen(false)} color="error">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

export default Usuarios;
