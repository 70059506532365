import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    Button,
    Paper,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Container,
} from '@mui/material';
import useStyles from './Turnos.styles';

const Turnos = () => {
    const classes = useStyles();
    const [profesionales, setProfesionales] = useState([]);
    const [formData, setFormData] = useState({
        profesional: '',
        nombre: '',
        apellido: '',
        dia: '',
        hora: '',
        dni: '',
        telefono: '',
        motivo: '',
        comentario: '',
    });
    const [horasDisponibles, setHorasDisponibles] = useState([]);
    const [empresa, setEmpresa] = useState('');
    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        fetch('/api/login/session')
            .then((res) => res.json())
            .then((user) => {
                setEmpresa(user.empresa);
                fetchProfesionales(user.empresa);
            })
            .catch((error) => console.error('Error al obtener la empresa del usuario:', error));
    }, []);

    const fetchProfesionales = (empresa) => {
        fetch(`/api/turnos/profesionales?empresa=${empresa}`)
            .then((res) => res.json())
            .then((data) => setProfesionales(data))
            .catch((error) => console.error('Error al cargar profesionales:', error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleDiaChange = (e) => {
        const dia = e.target.value;
        setFormData((prev) => ({ ...prev, dia }));

        if (formData.profesional && dia) {
            fetch(`/api/turnos/horas_disponibles?profesional=${formData.profesional}&dia=${dia}`)
                .then((res) => res.json())
                .then((data) => setHorasDisponibles(data))
                .catch((error) => console.error('Error al cargar horas disponibles:', error));
        }
    };

    const handleSave = () => {
        const payload = { ...formData, empresa };

        fetch('/api/turnos/turno', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.success) {
                    alert('Turno agendado exitosamente');
                    setFormData({
                        profesional: '',
                        nombre: '',
                        apellido: '',
                        dia: '',
                        hora: '',
                        dni: '',
                        telefono: '',
                        motivo: '',
                        comentario: '',
                    });
                } else {
                    alert(`Error al agendar turno: ${response.error}`);
                }
            })
            .catch((error) => console.error('Error al guardar turno:', error));
    };

    const handleSearch = () => {
        fetch(`/api/turnos/turnos?search=${search}`)
            .then((res) => res.json())
            .then((data) => setResults(data))
            .catch((error) => console.error('Error al buscar turnos:', error));
    };

    const handleDelete = (id) => {
        if (window.confirm('¿Está seguro de eliminar este turno?')) {
            fetch(`/api/turnos/turno/${id}`, { method: 'DELETE' })
                .then((res) => res.json())
                .then((response) => {
                    if (response.success) {
                        alert('Turno eliminado exitosamente');
                        handleSearch();
                    } else {
                        alert(`Error al eliminar turno: ${response.error}`);
                    }
                })
                .catch((error) => console.error('Error al eliminar turno:', error));
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Container className={classes.container}>
            <Typography variant="h4" gutterBottom>
                Gestión de Turnos
            </Typography>
            <Paper className={classes.paper}>
                <Typography variant="h6">Agendar Turno</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Profesional</InputLabel>
                            <Select
                                name="profesional"
                                value={formData.profesional}
                                onChange={handleChange}
                            >
                                {profesionales.map((prof) => (
                                    <MenuItem key={prof.usuario} value={prof.usuario}>
                                        {prof.usuario}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Nombre"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Apellido"
                            name="apellido"
                            value={formData.apellido}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="DNI"
                            name="dni"
                            value={formData.dni}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            type="date"
                            label="Día"
                            name="dia"
                            value={formData.dia}
                            onChange={handleDiaChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Hora</InputLabel>
                            <Select
                                name="hora"
                                value={formData.hora}
                                onChange={handleChange}
                            >
                                {horasDisponibles.map((hora) => (
                                    <MenuItem key={hora} value={hora}>
                                        {hora}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Teléfono"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Motivo"
                            name="motivo"
                            value={formData.motivo}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Comentario"
                            name="comentario"
                            value={formData.comentario}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    className={classes.button}
                >
                    Guardar
                </Button>
            </Paper>
            <Paper className={classes.paper}>
                <Typography variant="h6">Buscar Turnos</Typography>
                <TextField
                    fullWidth
                    label="Buscar"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className={classes.field}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSearch}
                    className={classes.button}
                >
                    Buscar
                </Button>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Profesional</TableCell>
                            <TableCell>Fecha/Hora</TableCell>
                            <TableCell>DNI</TableCell>
                            <TableCell>Nombre/Apellido</TableCell>
                            <TableCell>Acción</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((turno) => (
                                <TableRow key={turno.id}>
                                    <TableCell>{turno.profesional}</TableCell>
                                    <TableCell>{`${new Date(turno.fecha).toLocaleDateString()} - ${turno.hora}`}</TableCell>
                                    <TableCell>{turno.dni}</TableCell>
                                    <TableCell>{`${turno.nombre} ${turno.apellido}`}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleDelete(turno.id)}
                                        >
                                            X
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={results.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Container>
    );
};

export default Turnos;
