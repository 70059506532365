import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Menu from './modules/Menu';
import Login from './modules/Login';
import Home from './modules/Home';
import Usuarios from './modules/Usuarios';
import Agenda from './modules/Agenda';
import Llamado from './modules/Llamado';
import SalaEspera from './modules/Sala_espera';
import Turnos from './modules/Turnos';
import Pacientes from './modules/Pacientes'; // Importación del módulo Pacientes

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true); // Estado para manejar la carga inicial

    useEffect(() => {
        // Verificar la sesión del usuario al cargar la aplicación
        const checkSession = async () => {
            try {
                const response = await fetch('/api/login/session');
                if (response.ok) {
                    const user = await response.json();
                    setIsAuthenticated(true); // Establecer sesión válida
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error al verificar la sesión:', error);
            } finally {
                setLoading(false); // Finaliza la carga inicial
            }
        };

        checkSession();
    }, []);

    const handleLogin = () => {
        setIsAuthenticated(true); 
    };

    const handleLogout = async () => {
        try {
            const response = await fetch('/api/login/logout', { method: 'POST' });
            if (response.ok) {
                setIsAuthenticated(false);
                localStorage.removeItem('empresa'); // Limpiar datos locales si se utilizan
            } else {
                console.error('Error al cerrar sesión en el backend.');
            }
        } catch (error) {
            console.error('Error al conectar con el backend para cerrar sesión:', error);
        }
    };

    if (loading) {
        // Mostrar un indicador de carga mientras se verifica la sesión
        return <div>Cargando...</div>;
    }

    return (
        <Router>
            {isAuthenticated && <Menu onLogout={handleLogout} />}
            <Box sx={{ marginLeft: isAuthenticated ? { md: '250px' } : 0, padding: 2 }}>
                <Routes>
                    <Route path="/" element={<Login onLogin={handleLogin} />} />
                    {isAuthenticated ? (
                        <>
                            <Route path="/home" element={<Home />} />
                            <Route path="/pacientes" element={<Pacientes />} /> {/* Ruta para Pacientes */}
                            <Route path="/agenda" element={<Agenda />} />
                            <Route path="/usuarios" element={<Usuarios />} />
                            <Route path="/turnos" element={<Turnos />} />
                            <Route path="/llamado" element={<Llamado />} />
                            <Route path="/sala_espera" element={<SalaEspera />} />
                            <Route path="*" element={<Navigate to="/home" replace />} />
                        </>
                    ) : (
                        <Route path="*" element={<Navigate to="/" replace />} />
                    )}
                </Routes>
            </Box>
        </Router>
    );
};

export default App;
